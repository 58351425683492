import Gauge from '@/components/Gauge';
import { toPercentage } from '@/utilities/formatting';

interface Props {
  companyROE: number;
  companyName: string;
  industryROE: number;
  industryName: string;
  labels?: {
    title: string;
    company: string;
    industry: string;
    notAvailable: string;
  };
}

export const ROEGauge = ({
  companyROE,
  industryROE,
  companyName,
  industryName,
  labels = {
    title: 'Future ROE (3yrs)',
    company: 'Company',
    industry: 'Industry',
    notAvailable: 'n/a',
  },
}: Props) => {
  return (
    <Gauge
      title="ROE"
      keys={['company', 'industry']}
      keyLabels={{
        company: labels.company,
        industry: labels.industry,
      }}
      entityTitles={{
        company: companyName,
        industry: industryName,
      }}
      entities={{
        company: companyROE,
        industry: industryROE,
      }}
      formatter={(value: number | null) =>
        value !== null ? toPercentage(value, 1) : labels.notAvailable
      }
      numTicks={5}
      from={0}
      to={0.4}
    />
  );
};
