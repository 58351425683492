import { ROUTE_MY_PORTFOLIOS, ROUTE_PORTFOLIO_DEMO } from '@/constants/routes';
import { getAuthCookie } from '@/utilities/cookies';
import { useMount } from '@simplywallst/ui-core';
import { useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';

/**
 *
 * This component renders when users land on `/portfolio`
 * and is responsible for redirection based on the
 * authentication status of the user.
 *
 * - authenticated = `/portfolio/my-portfolios`
 * - anonymous = `/discover/sample-portfolios`
 *
 */
export function PortfolioLanding() {
  const dispatch = useDispatch();
  useMount(() => {
    async function doRedirect() {
      const cookie = await getAuthCookie();
      const redirectTo =
        typeof cookie !== 'undefined' &&
        typeof cookie.accessToken === 'string' &&
        cookie.accessToken !== ''
          ? ROUTE_MY_PORTFOLIOS
          : ROUTE_PORTFOLIO_DEMO;
      dispatch(redirect({ type: redirectTo }));
    }
    doRedirect();
  });
  return null;
}
