import {
  init,
  setUser as sentrySetUser,
  captureException,
  captureEvent,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/capacitor';
import { getCurrentRoute } from './utils';
import { getCurrentRouteObject } from '@/router/utils';

export function initialiseSentry(config: Parameters<typeof init>[0]) {
  init({
    /**
     * duplicated from web init to allow more flexibility with web vs native
     */
    beforeSend: (event) => {
      const route = getCurrentRouteObject();
      const team = route.team;
      const teamTagAlreadySet = event.tags?.team;

      if (team && !teamTagAlreadySet) {
        event.tags = {
          ...event.tags,
          team,
        };
      }
      return event;
    },
    integrations: [
      browserTracingIntegration({
        beforeStartSpan: (context) => {
          const route = getCurrentRoute();
          return {
            ...context,
            name: route,
          };
        },
      }),
      replayIntegration(),
    ],
    ...config,
  });
}

export function setUser(user: Parameters<typeof sentrySetUser>[0]) {
  sentrySetUser(user);
}

export function logError(error: unknown) {
  captureException(error);
}

export const logEvent: typeof captureEvent = (event, hint) => {
  return captureEvent(event, hint);
};
