import Gauge from '@components/Gauge';
import { toPercentage } from '@/utilities/formatting';

export interface Props {
  portfolioROE: number;
  portfolioName: string;
  marketROE: number;
  marketName: string;
}

const keys = ['portfolio', 'market'];

const ROEGauge = ({
  portfolioROE,
  marketROE,
  portfolioName,
  marketName,
}: Props) => {
  const entities = {
    [keys[0]]: portfolioROE,
    [keys[1]]: marketROE,
  };
  const entityTitles = {
    [keys[0]]: portfolioName,
    [keys[1]]: marketName,
  };
  return (
    <Gauge
      title="ROE"
      keys={keys}
      entities={entities}
      entityTitles={entityTitles}
      formatter={(value: number | null) =>
        value !== null ? toPercentage(value, 1) : 'n/a'
      }
      numTicks={5}
      from={0}
      to={0.4}
    />
  );
};

export default ROEGauge;
